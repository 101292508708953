import { animeStartsWithinTwoWeeks } from "../datehelper";
export const filterTitle = (anime, text) => {
    if ((anime.title.english || anime.title.romaji) === undefined) {
        return false;
    }
    return (anime.title.english || anime.title.romaji)
        .toLowerCase()
        .includes(text.toLowerCase());
};
export const filterStudios = (anime, text) => {
    return anime.studios.edges.filter(edge => edge.isMain).find(studio => studio.node.name.toLowerCase().includes(text.toLowerCase()));
};
export const filterTitleStartsWith = (anime, text) => {
    return (anime.title.english || anime.title.romaji)
        .toLowerCase()
        .startsWith(text.toLowerCase());
};
export const filterCurrent = (anime, current) => {
    return (!current ||
        (current && (anime.status.toLowerCase().includes("releasing") ||
            (anime.status.toLowerCase().includes("not_yet_released") &&
                animeStartsWithinTwoWeeks(anime)))));
};
export const filterYear = (anime, year) => {
    return `${anime.seasonYear}`.includes(year);
};
export const filterSeason = (anime, season) => {
    return anime.season.toString().toLowerCase().includes(season.toLowerCase());
};
export const filterText = (anime, text) => {
    return filterTitle(anime, text) || filterStudios(anime, text);
};
export const filterList = (list, filters) => {
    return list
        .filter((anime) => filterText(anime, filters.text))
        .filter((anime) => filterCurrent(anime, filters.current))
        .filter((anime) => filterYear(anime, filters.year))
        .filter((anime) => filterSeason(anime, filters.season));
};
